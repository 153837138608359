/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../assets/css/bootstrap.min.css"
import "../assets/css/now-ui-kit.min.css"
import Navbar from "./general/navbar.de"
import Footer from "./general/FooterDefault.de"

const Layout = ({ children }) => {
  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse")
    document.documentElement.classList.remove("nav-open")
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse")
    }
  })

  return (
    <>
      <Navbar />
      <div>
        <main>{children}</main>
        <Footer classes="footer" />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
